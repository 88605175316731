<template>
    <div class="bar-chart svg-container" ref="pieChart">
        <svg :width="svgWidth + svgPaddingX" :height="svgWidth + svgPaddingY" v-if="redrawToggle">
            <g ref="backgroundPieChartPath"
               :transform="`translate(${(svgWidth + svgPaddingX) / 2}, ${(svgWidth + svgPaddingY) / 2})`">
                <path></path>
            </g>
            <g ref="pieChartPath"
               :transform="`translate(${(svgWidth + svgPaddingX) / 2}, ${(svgWidth + svgPaddingY) / 2})`">
                <path></path>
            </g>
            <g :transform="`translate(${(svgWidth + svgPaddingX) / 2}, ${(svgWidth + svgPaddingY) / 2})`">
                <text y="5px" dominant-baseline="middle" text-anchor="middle" font-size="50" font-weight="bold">
                    {{ highRisk + mediumHighRisk + mediumLowRisk + lowRisk }}
                </text>
            </g>
        </svg>
    </div>
</template>

<script>
import { select } from 'd3-selection';
import { arc, pie } from 'd3-shape';
import { interpolate } from 'd3-interpolate';
import 'd3-transition';

export default {
    name: 'PieChart',
    props: {
        totalCount: Number,
        highRisk: Number,
        mediumHighRisk: Number,
        mediumLowRisk: Number,
        lowRisk: Number,
        svgPaddingX: {
            type: Number,
            default: 10
        },
        svgPaddingY: {
            type: Number,
            default: 10
        }
    },
    data() {
        return {
            svgWidth: 0,
            redrawToggle: true,
            chartColor: ['#FD6161', '#FFBC3A', '#FFD700', '#07CD32', '#E0E0E0'], // 高、中高、中低、低、背景颜色
            windowWidth: window.innerWidth
        };
    },
    computed: {
        dataArray() {
            const values = [
                this.highRisk,
                this.mediumHighRisk,
                this.mediumLowRisk,
                this.lowRisk
            ];
            const totalRisks = values.reduce((a, b) => a + b, 0);
            let background = this.totalCount - totalRisks;

            if (background < 0) {
                console.warn('Total count is less than the sum of risk levels.');
                background = 0;
            }

            return [...values, background];
        },
        arcWidth() {
            return (this.svgWidth * 0.15 > 30) ? 30 : this.svgWidth * 0.15;
        },
        arcRadius() {
            // 計算弧形有圓角的
            return arc()
                .innerRadius(this.svgWidth / 3)
                .outerRadius(this.svgWidth / 3 + this.arcWidth)
                .cornerRadius(this.svgWidth / 2);
        },
        arc() {
            // 計算弧形
            return arc()
                .innerRadius(this.svgWidth / 3)
                .outerRadius(this.svgWidth / 3 + this.arcWidth);
        },
        pie() {
            return pie()
                .value(d => Number(d))
                .sort(null);
        }
    },
    watch: {
        dataArray: {
            handler() {
                this.animateLoad();
            },
            deep: true
        }
    },
    methods: {
        animateLoad() {
            const vm = this;

            select(vm.$refs.pieChartPath)
                .selectAll('path')
                .data(vm.pie(vm.dataArray))
                .join('path')
                .attr('fill', (d, i) => vm.chartColor[i])
                .transition()
                .duration(800)
                .attrTween('d', (d) => {
                    const interpolateAngle = interpolate(d.startAngle + 0.1, d.endAngle);
                    return (t) => {
                        d.endAngle = interpolateAngle(t);
                        return vm.arc(d);
                    };
                });

            select(vm.$refs.backgroundPieChartPath)
                .selectAll('path')
                .data(vm.pie([this.dataArray[4]]))
                .join('path')
                .attr('fill', vm.chartColor[4])
                .transition()
                .duration(900)
                .attrTween('d', (d) => {
                    const interpolateAngle = interpolate(d.startAngle + 0.1, d.endAngle);
                    return (t) => {
                        d.endAngle = interpolateAngle(t);
                        return vm.arcRadius(d);
                    };
                });
        },
        addResizeListener() {
            window.addEventListener('resize', () => {
                const vm = this;
                if (vm.windowWidth === window.innerWidth) return;
                vm.redrawToggle = false;
                setTimeout(() => {
                    vm.redrawToggle = true;
                    vm.$nextTick(function() {
                        this.svgWidth = Number(this.$refs.pieChart.offsetWidth - this.svgPaddingX);
                        this.animateLoad();
                    });
                }, 0);
            });
        }
    },
    created() {
    },
    mounted() {
        this.$nextTick(function() {
            this.svgWidth = Number(this.$refs.pieChart.offsetWidth - this.svgPaddingX);
            this.animateLoad();
            this.addResizeListener();
        });
    }
};
</script>

<style lang="scss" scoped>
.svg-container {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 1%;
    vertical-align: top;
    overflow: hidden;
}
</style>
